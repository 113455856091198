import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import catAndHumanIllustration from '../images/cat-and-human-illustration.svg'

function IndexPage () {
  return (
    <Layout>
      <SEO keywords={[`aslade`]} title='Home' />
      <section class='px-4 md:pt-16 text-gray-800'>
        <div class='mt-10'>
          <h1 class='sm:float-left text-3xl sm:text-4xl md:text-5xl mb-16 font-light leading-tight font-heading max-w-xs md:max-w-md'><span class='text-orange-500 leading-relaxed'>👋 Hi, I'm Alex</span> <br /> I help people start software businesses</h1>
          <img src='https://looperinsights.com/wp-content/uploads/2019/02/alex.jpg' class='hidden sm:block ml-12 w-4/12 mt-2 ml-8 mb-12 rounded float-right shadow-md' alt=''
          />
          <p class='text-lg md:text-xl mb-8 leading-relaxed max-w-md md:max-w-lg' style={{clear: 'left'}}>
            I'm an experienced developer who specializes in early-stage startups.
          </p>
          <p class='text-lg md:text-xl mb-8 leading-relaxed max-w-md md:max-w-lg'>
            I work with founders on a range of technology, product, and business issues. Such as:
          </p>
          <ul class='text-lg md:text-xl mb-8 list-disc pl-4 mt-6'>
            <li class='mb-2'>
              Building great software, as quickly as possible
            </li>
            <li class='mb-2'>
              Recruiting developers and designers
            </li>
            <li class='mb-2'>
              Fostering an excellent company culture
            </li>
            <li class='mb-2'>
              Leading effective product teams
            </li>
          </ul>
          <p className='text-lg md:text-xl mb-16 leading-relaxed max-w-md md:max-w-lg'>
            If you're starting a software business, I'd love to talk.
          </p>
          <a href='mailto:alex@aslade.com' class='leading-tight bg-blue-700 hover:bg-blue-800 mb-20 text-white text-lg font-semibold py-3 px-5 rounded shadow-lg'>Get in touch</a>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
